<template>
  <div class="invest-apply">
    <template v-if="$store.state.account.loggedIn">
      <div class="container">
        <div class="content">
          <div class="steps">
            <ul class="tight">
              <li class="pointer" :class="{ 'active color-point': state.step === idx + 1 }" v-for="(s, idx) in state.steps" :key="idx" @click="move(idx + 1)">
                <span class="step font-xs">STEP {{ idx + 1 }}</span>
                <div class="title font-sm">{{ s.title }}</div>
                <span class="ico">
                  <span class="img" :style="{'background-image':`url(/assets/ico/${s.ico})`}"></span>
                </span>
              </li>
            </ul>
          </div>
          <div class="core">
            <div class="wrapper">
              <div class="header">
                <b class="title font-xl">{{ state.steps[state.step - 1].title }}</b>
                <div class="desc font-base">{{ state.steps[state.step - 1].desc }}</div>
              </div>
              <InvestApplyStep1 :form="state.form" :files="state.files" :validators="state.validators" :mode="state.mode" v-show="state.step === 1"/>
              <InvestApplyStep2 :form="state.form" :files="state.files" :validators="state.validators" :mode="state.mode" v-show="state.step === 2"/>
              <div class="actions" v-if="state.mode !== 'view'">
                <div class="row">
                  <div class="col-lg-3 pr-lg-0">
                    <button class="prev btn btn-light btn-block" @click="move(state.step - 1)">이전으로</button>
                  </div>
                  <div class="col-lg-6 pr-lg-0">
                    <button class="next btn btn-point btn-block" @click="submit()" v-if="state.step === state.steps.length">{{ state.mode === "edit" ? "수정하기" : "프로젝트 신청" }}</button>
                    <button class="next btn btn-point btn-block" @click="move(state.step + 1, true)" v-else>다음 단계</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="container" v-else-if="$store.state.account.checked">
      <NoLogin/>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onUnmounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import InvestApplyStep1 from "./InvestApplyStep1";
import InvestApplyStep2 from "./InvestApplyStep2";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import NoLogin from "@/components/NoLogin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageApplyInvestApply";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoLogin, InvestApplyStep1, InvestApplyStep2},
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      if (store.state.account.memberType !== "C") {
        state.escapable = true;
        store.commit("setSwingMessage", "법인회원이 아닙니다. 마이페이지로 이동합니다.");
        return router.replace({path: "/mypage"});
      }

      applicationSeq = router.app.$route.params.applicationSeq;

      if (applicationSeq) {
        http.get(`/api/invest/projects/${applicationSeq}/apply`).then(({data}) => {
          state.form.businessTel = data.body.project.businessTel;
          state.form.corpEstablished = data.body.project.corpEstablished;
          state.form.corpType = data.body.project.corpType;
          state.form.homepage = data.body.project.homepage;
          state.form.managerName = data.body.project.managerName;
          state.form.managerEmail = data.body.project.managerEmail;
          state.form.managerHp = data.body.project.managerHp;
          state.form.applicationAmt = data.body.project.applicationAmt;
          state.form.businessPost = data.body.project.businessPost;
          state.form.businessAddr1 = data.body.project.businessAddr1;
          state.form.businessAddr2 = data.body.project.businessAddr2;

          state.files = data.body.fileList;

          if (data.body.project.regState === "STATUS_0003") {
            state.mode = "view";
          } else {
            state.mode = "edit";
          }
        });
      } else {
        state.mode = "write";
      }

      store.dispatch("setDocumentTitle", "프로젝트 신청");

      window.onbeforeunload = () => {
        return definitions.messages.beforeProjectApplyStop;
      };
    });

    const state = reactive({
      step: 1,
      mode: "view",
      validators: {},
      files: [],
      finish: false,
      escapable: false,
      form: {
        corporateName: null,
        ceoName: null,
        businessNum: null,
        corporateNum: null,
        businessTel: null,
        businessPost: null,
        businessAddr1: null,
        businessAddr2: null,
        homepage: null,
        corpEstablished: null,
        corpType: null,
        managerName: null,
        managerEmail: null,
        managerHp: null,
        applicationAmt: null
      },
      steps: [
        {title: "기업 정보", desc: "기업 정보는 마이페이지 - 계정 정보에서 수정이 가능해요.", ico: "page.apply.invest.step1.svg"},
        {title: "담당자 정보", desc: "담당자 정보를 입력해주세요.", ico: "page.apply.invest.step2.svg"}
      ]
    });

    let applicationSeq;

    const submit = (step = 1) => {
      const request = async () => {
        const args = lib.getRenewed(state.form);
        args.corpEstablished = args.corpEstablished?.replaceAll("-", "");
        let res1;

        if (state.mode === "edit") {
          //args.applicationSeq = router.app.$route.params.applicationSeq;
          args.projectFileItems = [];

          for (let i in state.files) {
            if (state.files[i].fileOrigName) {
              args.projectFileItems.push(state.files[i]);
            }
          }

          res1 = await http.put(`/api/invest/projects/${applicationSeq}/apply`, args).catch(httpError());
        } else {
          res1 = await http.post("/api/invest/projects/apply", args).catch(httpError());
          applicationSeq = res1.data.body.applicationSeq;
        }

        if (res1?.error) {
          return;
        }

        // 최대 업로드 크기 설정으로 인해 나누어서 업로드
        for (let i in state.files) {
          if (state.files[i].fileOrigName) {
            continue;
          }

          const formData = new FormData();
          formData.append("subImgFiles", state.files[i]);

          const res2 = await http.post(`/api/invest/projects/${applicationSeq}/uploads`, formData).catch(httpError());

          if (res2?.error) {
            return;
          }
        }

        state.finish = true;
        window.onbeforeunload = null;
        store.commit("setSwingMessage", `프로젝트를 ${state.mode === "edit" ? "수정" : "신청"}하였습니다.`);

        if (state.mode === "edit") {
          return router.push({path: "/mypage/open/invest"});
        } else {
          return router.push({path: "/apply/investApplyDone"});
        }
      };

      move(step);
      nextTick(() => {
        if (state.validators[step]()) {
          step === Object.keys(state.validators).length ? request() : submit(step + 1);
        }
      });
    };

    const move = (step, valid) => {
      if (step === state.step) {
        return;
      } else if (step < 1) {
        return router.push({path: "/apply/rewardIntro"});
      } else if (valid && !state.validators[step - 1]()) {
        return;
      }

      state.step = step;
      window.scrollTo(0, 0);
    };

    onUnmounted(() => {
      window.onbeforeunload = null;
    });

    return {component, state, submit, move};
  },
  beforeRouteLeave(to, from, next) {
    if (this.state.escapable || this.state.finish || !this.$store.state.account.checked || !this.$store.state.account.loggedIn || confirm(definitions.messages.beforeProjectApplyStop)) {
      next();
    }
  }
});
</script>

<style lang="scss" scoped>
.invest-apply {
  background: $colorBackground;

  > .container {
    padding-top: $px33;
    padding-bottom: $px33;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      color: #666;
    }

    .content {
      position: relative;
      padding-left: $px170;

      .steps {
        width: $px170;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: $px50;

        ul {
          position: relative;
          height: 100%;

          li {
            margin: 0 0 $px50 0;
            position: relative;
            padding-left: $px52;

            .step {
              margin-bottom: $px2;
              display: inline-block;
            }

            .ico {
              position: absolute;
              top: $px5;
              left: 0;
              width: $px40;
              height: $px40;
              border-radius: 50%;
              border-width: $px1;
              border-style: solid;
              border-color: #eee;
              background: #3c3c3c;

              .img {
                width: $px20;
                height: $px20;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            &.active {
              .ico {
                background: $colorPoint;
              }

              .title {
                font-weight: 500;
              }
            }

            .completed {
              .img {
                border-color: $colorPoint;
              }
            }

            &:not(.active):not(.completed) {
              .step, .title {
                opacity: 0.75;
              }

              &:hover {
                .step, .title {
                  opacity: 1;
                }
              }
            }
          }

          //&:before {
          //  content: " ";
          //  display: inline-block;
          //  width: $px1;
          //  height: 100%;
          //  position: absolute;
          //  top: 0;
          //  right: 0;
          //  background: #ddd;
          //  margin-right: $px12;
          //}
        }
      }

      .core {
        > .wrapper {
          background: #fff;
          padding: $px50;
          border: $px1 solid #eee;

          .header {
            padding-bottom: $px40;

            .title {
              display: block;
              margin-bottom: $px9;
            }
          }
        }

        .actions {
          max-width: $px450;

          .btn {
            padding: $px18 0;

            &.prev {
              border-color: #eee;
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .container {
      .content {
        padding: 0;

        .steps {
          display: none;
        }

        .core {
          > .wrapper {
            padding: $px30;

            .actions > .row > div:not(:last-child) .btn {
              margin-bottom: $px8;
            }
          }
        }
      }
    }
  }
}
</style>