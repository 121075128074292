<template>
  <div class="step2 apply">
    <div class="row">
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}ManagerName`">
          <span class="color-point">*</span>
          <span> 담당자 이름</span>
        </label>
        <input :id="`${component.name}ManagerName`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 홍길동" v-model="form.managerName" @keyup.enter="save()" :disabled="mode === 'view'"/>
      </div>
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}ManagerEmail`">
          <span class="color-point">*</span>
          <span> 담당자 이메일</span>
        </label>
        <input :id="`${component.name}ManagerEmail`" type="email" class="form-control border-focus-point font-sm" placeholder="ex) omc@ohmycompany.com" v-model="form.managerEmail" @keyup.enter="save()" :disabled="mode === 'view'"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}ManagerHp`">
          <span class="color-point">*</span>
          <span> 담당자 휴대전화</span>
        </label>
        <input :id="`${component.name}ManagerHp`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 01012345678" v-model="form.managerHp" @keyup.enter="save()" :disabled="mode === 'view'"/>
      </div>
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}ApplicationAmt`">
          <span class="color-point">*</span>
          <span> 신청 금액</span>
        </label>
        <Number :id="`${component.name}ApplicationAmt`" placeholder="(단위: 원)" :value.sync="form.applicationAmt" :disabled="mode === 'view'"/>
      </div>
    </div>
    <div class="steps">
      <b class="subject">진행 절차</b>
      <ul class="tight">
        <li v-for="(s, idx) in steps" :key="idx">
          <span>
            <span :style="{backgroundImage: `url('/assets/ico/${s.ico}')`}"></span>
          </span>
          <div>{{ s.subject }}</div>
          <i class="fa fa-caret-right"></i>
        </li>
      </ul>
    </div>
    <div class="alert">
      <ul class="tight">
        <li>
          <p>
            <b>안내</b>
          </p>
        </li>
        <li>
          <div>프로젝트 진행 확정 시, 법인이메일 및 법인대표자계정으로 법인회원 가입을 해주시기 바랍니다.</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Address from "@/components/Address";
import Number from "@/components/Number";
import {defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageApplyInvestApplyStep2";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    validators: Object,
    save: Function,
    mode: String
  },
  components: {Address, Number},
  setup(props) {
    const component = new Component(() => {
      props.validators[2] = () => {
        const warn = (message, id) => {
          store.commit("setSwingMessage", message);

          if (id) {
            document.getElementById(id).focus();
          }

          return false;
        };

        if (!props.form.managerName) {
          return warn("담당자 이름을 입력해주세요.", `${component.name}ManagerName`);
        } else if (!props.form.managerEmail) {
          return warn("담당자 이메일을 입력해주세요.", `${component.name}ManagerEmail`);
        } else if (!props.form.managerHp) {
          return warn("담당자 휴대전화 번호를 입력해주세요.", `${component.name}ManagerHp`);
        } else if (!props.form.applicationAmt) {
          return warn("신청 금액을 입력해주세요.", `${component.name}ApplicationAmt`);
        }

        return true;
      };
    });

    const steps = [
      {ico: "page.apply.invest.intro.step1.png", subject: "회원가입\n및 투자모집 신청"},
      {ico: "page.apply.invest.intro.step2.png", subject: "내부 심사\n및 기업사실확인"},
      {ico: "page.apply.invest.intro.step3.png", subject: "펀딩 진행\n 확정 및 등록"},
      {ico: "page.apply.invest.intro.step4.png", subject: "청약 진행"},
      {ico: "page.apply.invest.intro.step5.png", subject: "펀딩 성공"}
    ];

    return {component, steps};
  }
});

</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step2 {
  .steps {
    padding-top: $px20;

    .subject {
      margin-left: $px-2;
    }

    ul {
      text-align: center;
      margin-top: $px17;

      li {
        display: inline-block;
        width: $px133;
        text-align: center;
        vertical-align: top;
        margin-top: $px10;
        margin-right: $px35;
        margin-bottom: $px40;
        position: relative;

        span {

          display: inline-block;
        }

        > span {
          padding: $px18 0;
          margin-bottom: $px20;
          border-radius: 50%;
          background: $colorBackground;
          border: $px1 solid #eee;
          vertical-align: top;
          width: $px100;
          height: $px100;

          > span {
            width: $px61;
            height: $px60;
            background-size: auto;
            background-position: 50%;
            background-repeat: no-repeat;
          }
        }

        div {
          white-space: pre-line;
        }

        i {
          position: absolute;
          top: $px45;
          right: $px-20;
        }

        &:last-child {
          margin-right: 0;

          i {
            display: none;
          }
        }
      }
    }
  }

  .alert {
    border: $px1 solid #eee;
    background: $colorBackground;
    padding: $px20;
    font-size: $px13;
    color: #3c3c3c;
    margin-bottom: $px25;

    p {
      margin-bottom: $px5;
    }
  }

  @media(max-width: 767px) {
    .steps ul li {
      width: 100%;
      margin-right: 0;

      i {
        display: none;
      }
    }
  }
}
</style>