<template>
  <div class="step1 apply">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="corporateName" class="subject font-sm">기업명(법인명)</label>
        <input type="text" class="form-control border-focus-point font-sm" id="corporateName" placeholder="ex) 오마이컴퍼니" v-model="form.corporateName" @keyup.enter="save()" readonly/>
      </div>
      <div class="form-group col-md-6">
        <label for="ceoName" class="subject font-sm">대표자명</label>
        <input type="text" class="form-control border-focus-point font-sm" id="ceoName" placeholder="ex) 홍길동" v-model="form.ceoName" @keyup.enter="save()" readonly/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="businessNum" class="subject font-sm">사업자등록번호 </label>
        <input type="text" class="form-control border-focus-point font-sm" id="businessNum" placeholder="ex) 2001234567" v-model="form.businessNum" @keyup.enter="save()" readonly/>
      </div>
      <div class="form-group col-md-6">
        <label for="corporateNum" class="subject font-sm">법인등록번호</label>
        <input type="text" class="form-control border-focus-point font-sm" id="corporateNum" placeholder="13자리 숫자" v-model="form.corporateNum" @keyup.enter="save()" readonly/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}BusinessTel`">
          <span class="color-point">*</span>
          <span> 전화번호</span>
        </label>
        <input :id="`${component.name}BusinessTel`" type="tel" class="form-control border-focus-point font-sm" placeholder="ex) 021234567" v-model="form.businessTel" @keyup.enter="save()" :disabled="mode === 'view'"/>
      </div>
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}CorpEstablished`">
          <span class="color-point">*</span>
          <span> 설립일</span>
        </label>
        <Date :id="`${component.name}CorpEstablished`" class="font-sm" placeholder="ex) 20220429" :value.sync="form.corpEstablished" :disabled="mode === 'view'"/>
      </div>
    </div>
    <div class="form-group">
      <label class="font-sm" :for="`${component.name}BusinessAddress`">
        <span class="color-point">*</span>
        <span> 회사 주소</span>
      </label>
      <Address :id="`${component.name}BusinessAddress`" :zoneCode.sync="form.businessPost" :address1.sync="form.businessAddr1" :address2.sync="form.businessAddr2" :enter="save" :disabled="mode === 'view'"/>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="subject font-sm" :for="`${component.name}CorpType`">
          <span class="color-point">*</span>
          <span> 업종</span>
        </label>
        <select :id="`${component.name}CorpType`" class="form-control border-focus-point font-sm" v-model="form.corpType" :disabled="mode === 'view'">
          <option :value="null">업종을 선택해주세요.</option>
          <option v-for="(t, idx) in $definitions.codes.arr.corpType" :key="idx" :value="t.key">{{ t.value }}</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="homepage" class="subject font-sm">홈페이지</label>
        <input type="email" class="form-control border-focus-point font-sm" id="homepage" placeholder="https://www.example.com" v-model="form.homepage" @keyup.enter="save()" :disabled="mode === 'view'"/>
      </div>
    </div>
    <div class="form-group file">
      <label class="subject font-sm">
        <span>첨부 파일 ({{ computedFiles.length }}/{{ maxSubImgFilesCnt }})</span>
      </label>
      <div class="float-right" v-if="mode !== 'view'">
        <a class="pointer font-sm color-point" @click="setFiles()">파일 올리기</a>
      </div>
      <ul class="files tight" v-if="computedFiles.length">
        <li class="font-sm pointer" :data-type="f.type" v-for="(f, idx) in computedFiles" :key="idx" @click="$store.dispatch('previewFile', f)" title="클릭 시 미리보기">
          <template v-if="f.fileSeq">{{ f.fileOrigName }}</template>
          <template v-else>
            <div>{{ f.name }}</div>
          </template>
          <span class="times" title="삭제" @click.stop="delFile(idx)">&times;</span>
        </li>
      </ul>
      <div class="font-sm color-secondary mt-3">상세한 기업소개서, 사업자등록증, 법인등기부등본, 재무제표(최근3개년도) 및 기타 인증서류(벤처기업인증서, 이노비즈기업인증서, 메인비즈기업인증서 등)를 첨부해주세요.</div>
      <div class="alert alert-info">큰 용량의 파일을 업로드하시는 경우, 회사 이메일(omc@ohmycompany.com)로 발송해주시기 바랍니다.</div>
    </div>
  </div>
</template>

<script>
import Address from "@/components/Address";
import Date from "@/components/Date";
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageApplyInvestApplyStep1";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Array,
    validators: Object,
    save: Function,
    mode: String
  },
  components: {Address, Date},
  setup(props) {
    const component = new Component(() => {
      http.get(`/api/members/${store.state.account.memberSeq}/account`).then(({data}) => {
        props.form.corporateName = data.body.corporateName;
        props.form.ceoName = data.body.ceoName;
        props.form.businessNum = data.body.businessNum;
        props.form.corporateNum = data.body.corporateNum;
      }).catch(httpError(() => {
        return router.push({path: "/apply/intro"});
      }));

      props.validators[1] = () => {
        const warn = (message, id) => {
          store.commit("setSwingMessage", message);
          id && document.getElementById(id)?.focus();
          return false;
        };

        if (!props.form.businessTel) {
          return warn("전화번호를 입력해주세요.", `${component.name}BusinessTel`);
        } else if (!props.form.corpEstablished) {
          return warn("설립일을 입력해주세요.", `${component.name}CorpEstablished`);
        } else if (!props.form.businessAddr1 || !props.form.businessAddr1) {
          return warn("회사 주소를 입력해주세요.", `${component.name}BusinessAddress`);
        } else if (!props.form.corpType) {
          return warn("업종을 선택해주세요.", `${component.name}CorpType`);
        }

        if (props.files.length) {
          const maxRequestSize = lib.getMbToByte(definitions.limits.maxRequestSize.replace("MB", ""));
          const totalReqFileSize = props.files.map(f => f.size).reduce((sum, val) => sum + val);

          if (totalReqFileSize > maxRequestSize) {
            return warn(`첨부 파일의 용량이 너무 큽니다. 총 용량을 ${definitions.limits.maxRequestSize} 이하로 올려주세요.`);
          }
        }

        return true;
      };
    });

    const state = reactive({
      selects: [],
      options: [
        {name: "home", title: "홈페이지", ico: "/assets/ico/page.apply.reward.step1.home.svg", icoSelected: "/assets/ico/page.apply.reward.step1.home.selected.svg"},
        {name: "facebook", title: "페이스북", ico: "/assets/ico/page.apply.reward.step1.facebook.svg", icoSelected: "/assets/ico/page.apply.reward.step1.facebook.selected.svg"},
        {name: "blog", title: "블로그", ico: "/assets/ico/page.apply.reward.step1.blog.svg", icoSelected: "/assets/ico/page.apply.reward.step1.blog.selected.svg"},
        {name: "instagram", title: "인스타그램", ico: "/assets/ico/page.apply.reward.step1.instagram.svg", icoSelected: "/assets/ico/page.apply.reward.step1.instagram.selected.svg"},
      ]
    });

    const computedFiles = computed(() => {
      const files = [];

      for (let i in props.files) {
        if (props.files[i].fileSeq) {
          if (props.files[i].delFlag === "N") {
            const file = props.files[i];
            const arr = file.fileOrigName.split(".");
            const ext = arr[arr.length - 1];

            if (["jpg", "png", "gif", "bmp", "jpeg"].includes(ext)) {
              file.type = "image";
            }

            files.push(file);
          }
        } else {
          files.push(props.files[i]);
        }
      }

      return files;
    });

    const maxSubImgFilesCnt = 10;

    const corpTypes = ["농업 임업 및 어업", "광업", "제조업", "전기가스/증기 및 수도사업", "하수 폐기물 처리원료재생 및 환경복원업", "건설업", "도매 및 소매업", "운수업", "숙박 및 음식점업", "출판 영상 방송통신 및 정보서비스업", "금융 및 보험업", "부동산업 및 임대업", "사업시설관리 및 사업지원 서비스업", "공공행정국방 및 사회보장 행정", "교육 서비스업",
      "보건업 및 사회복지 서비스업", "예술 스포츠 및 여가관련서비스업", "협회 및 단체 /수리 및 기타개인서비스업", "가구내 고용활동 및 달리 분류되지 않은 자가소비 생산활동", "국제 및 외국기관"];

    const delFile = (idx) => {
      const file = computedFiles.value[idx];

      if (file.fileSeq) {
        file.delFlag = "Y";
      } else {
        props.files.splice(idx, 1);
      }
    };

    const setFiles = () => {
      const isAllowedCnt = (filesCnt = 1) => {
        return props.files.length + filesCnt <= maxSubImgFilesCnt;
      };

      if (!isAllowedCnt()) {
        store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${maxSubImgFilesCnt}개)를 초과하였습니다.`);
        return;
      }

      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files`,
          maxCnt: maxSubImgFilesCnt,
          maxCntLabel: maxSubImgFilesCnt,
          isAllowedCnt: isAllowedCnt,
        }
      });
    };

    const toggleOption = (idx) => {
      const idxOf = state.selects.indexOf(idx);

      if (idxOf >= 0) {
        state.selects.splice(idxOf, 1);
      } else {
        state.selects.push(idx);
      }
    };

    return {component, state, maxSubImgFilesCnt, corpTypes, delFile, setFiles, toggleOption, computedFiles};
  }
});

</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step1 {
  position: relative;

  .picture {
    position: absolute;
    top: $px-80;
    right: 0;

    .desc {
      position: absolute;
      top: $px-30;
      right: $px-154;
      padding: $px11;
      border: $px1 solid #eee;
      background: #f8f9fa;
      border-radius: $px15 $px15 $px15 0;

      span.badge {
        margin-left: $px3;
      }
    }
  }

  .buttons {
    padding-top: $px5;

    .part {
      display: inline-block;
      width: $px64;
      text-align: center;
      margin-right: $px13;
      transition: color 0.18s;
      position: relative;

      button {
        width: $px52;
        height: $px52;
        padding: $px9 0 0 0;
        margin-bottom: $px8;
        border-radius: 50%;
        position: relative;

        .img {
          height: $px17;
          width: $px17;
        }

        &:after {
          content: "\f096";
          display: inline-block;
          position: absolute;
          top: $px5;
          right: $px1;
          color: #ddd;
          height: $px13;
          line-height: 0.9;
          background: #fff;
          border-radius: $px4;
          font-family: "FontAwesome";
        }
      }

      div {
        opacity: 0.9;
      }

      i {
        position: absolute;
        top: $px5;
        right: $px1;
        color: #ddd;
        height: $px13;
        line-height: 0.9;
        background: #fff;
        border-radius: $px4;
      }

      &.selected {
        color: $colorPoint;

        button {
          border-color: $colorPoint;

          &:after {
            content: "\f046";
            color: $colorPoint;
          }
        }

        i {
          color: inherit;
        }
      }

      &.blog button .img {
        width: $px16;
        height: $px16;
      }
    }
  }

  @media(max-width: 991px) {
    .picture .desc {
      left: $px-154;
      right: auto;
      border-radius: $px15 $px15 0 $px15;
    }
  }
}
</style>